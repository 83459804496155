<template>
  <div>
    <b-card no-body>
      <b-row>
        <b-col>
          <div
            class="my-2 mx-2"
          >
            <b-row>
              <b-col
                class="d-flex justify-content-end align-items-center mb-1 mb-md-0"
              >
                <JsonExcel
                  class="btn p-0"
                  :data="rows"
                  :fields="json_fields"
                  type="csv"
                  :name="`Clients - Page ${page}.xls`"
                >
                  <b-button
                    v-b-tooltip.hover.v-primary="'Download Excel - Page'"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="mr-1"
                    size="sm"
                  >
                    <mdicon
                      name="Download"
                      size="13"
                    />
                  </b-button>
                </JsonExcel>
                <JsonExcel
                  class="btn p-0"
                  :fetch="getRegisterUserListNoPagination"
                  :before-generate="__showJsonDownloadAlert"
                  :fields="json_fields"
                  type="csv"
                  name="Clients.xls"
                >
                  <b-button
                    v-b-tooltip.hover.v-primary="'Download Excel - All'"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="mr-1"
                    size="sm"
                  >
                    <mdicon
                      name="DownloadMultiple"
                      size="13"
                    />
                  </b-button>
                </JsonExcel>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.v-primary="'Refresh Table'"
                  variant="outline-primary"
                  size="sm"
                  @click="onClickRefresh()"
                >
                  <feather-icon
                    icon="RefreshCwIcon"
                  />
                </b-button>
              </b-col>
            </b-row>
          </div>
          <b-overlay
            id="overlay-background"
            :show="loading"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="rows"
              :fields="fields"
              show-empty
              @sort-changed="sortChanged"
            >
              <template #cell(tenant.name)="data">
                {{ data.item.tenant ? data.value : 'N/A' }}
              </template>
              <template #cell(tenant.business_name)="data">
                {{ data.item.tenant ? data.value : 'N/A' }}
              </template>
              <template #cell(admin_email)="data">
                {{ data.item.admin_email ? data.value : 'N/A' }}
              </template>
              <template #cell(is_email_verified)="data">
                <div>
                  <b-badge :variant="`light-${data.value ? 'success' : 'danger'}`">
                    {{ data.value ? 'Active' : 'Inactive' }}
                  </b-badge>
                </div>
              </template>
              <template #cell(tenant.domain)="data">
                {{ data.item.tenant ? data.value : 'N/A' }}
              </template>
              <template #cell(tenant.country)="data">
                {{ data.item.tenant ? data.value : 'N/A' }}
              </template>
              <template #cell(tenant.status)="data">
                <b-badge :variant="data.value == 'Active' ? 'light-success' : data.value == 'Inactive' ? 'light-danger' : 'light-primary'">
                  {{ data.value || 'N/A' }}
                </b-badge>
              </template>
            </b-table>
          </b-overlay>

          <div class="mx-2 mb-2">
            <b-row
              cols="12"
            >
              <b-col
                class="d-flex align-items-center justify-content-sm-start"
                md="3"
              >
                <label class="width-75 mr-1">Per page</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block width-100"
                />
              </b-col>
              <!-- Pagination -->
              <b-col
                class="d-flex align-items-center justify-content-sm-end"
                md="9"
              >
                <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                <b-pagination
                  v-model="page"
                  :per-page="perPage"
                  :total-rows="(meta.total)? meta.total : 0"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BPagination,
  BTable,
  BOverlay,
  BButton,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import JsonExcel from 'vue-json-excel'
import { avatarText } from '@core/utils/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const registeredUserRepository = RepositoryFactory.get('registeredUser')

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCol,
    BRow,
    BCard,
    BPagination,
    BTable,
    BOverlay,
    BBadge,
    BButton,
    vSelect,
    JsonExcel,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      avatarText,
      total: 0,
      meta: {},
      loading: false,
      sort: '',
      value: 25,
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      filters: {},
      statusOptions: [
        {
          key: '1',
          name: 'Active',
        },
        {
          key: '0',
          name: 'Inactive',
        },
      ],
      fields: [
        {
          key: 'id',
          label: '#',
          thClass: 'd-none',
          tdClass: 'd-none',
        },
        {
          key: 'tenant.name',
          label: 'Admin Name',
          sortable: true,
        },
        {
          key: 'tenant.business_name',
          label: 'Business Name',
          sortable: true,
        },
        {
          key: 'admin_email',
          label: 'Admin Email',
          sortable: false,
        },
        {
          key: 'is_email_verified',
          label: 'Verified Email',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'tenant.domain',
          label: 'Domain',
          sortable: false,
        },
        {
          key: 'tenant.country',
          label: 'Country',
          sortable: false,
        },
        {
          key: 'tenant.status',
          label: 'Status',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      rows: [],
      json_fields: {
        'Admin Name': {
          field: 'tenant.name',
          callback: value => (value || 'N/A'),
        },
        'Business Name': {
          field: 'tenant.business_name',
          callback: value => (value || 'N/A'),
        },
        'Admin Email': {
          field: 'admin_email',
          callback: value => (value || 'N/A'),
        },
        'Email Type': {
          field: 'is_email_verified',
          callback: value => (value ? 'Active' : 'Inactive'),
        },
        Domain: {
          field: 'tenant.domain',
          callback: value => (value || 'N/A'),
        },
        Country: {
          field: 'tenant.country',
          callback: value => (value || 'N/A'),
        },
        Status: {
          field: 'tenant.status',
          callback: value => (value || 'N/A'),
        },
      },
    }
  },
  watch: {
    page() {
      this.getRegisterUserList()
    },
    perPage() {
      this.getRegisterUserList()
    },
  },
  async mounted() {
    this.totalRows = this.rows.length
  },
  methods: {
    async getRegisterUserList() {
      this.loading = true
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, ['filter[created_at]'])
        const { data } = (await registeredUserRepository.getRegisterUserList(this.page, this.filterQuery, this.perPage))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async getRegisterUserListNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, ['filter[created_at]'])
        const { data } = (await registeredUserRepository.getRegisterUserListNoPagination(this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    // REQUIRED
    filterQueryUpdate() {
      this.getRegisterUserList()
    },
    handleChangePage(page) {
      this.page = page
      this.getRegisterUserList()
    },
    changePage(value) {
      this.perPage = value
    },
    // REQUIRED
    sortChanged(e) {
      const key = e.sortBy
      if (key) {
        const active = e.sortDesc ? '-' : ''
        this.sort = active + key
        this.getRegisterUserList()
      }
    },
    onClickRefresh() {
      this.getRegisterUserList()
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style lang="scss">
@import '../@core/scss/vue/libs/vue-select.scss';
</style>
